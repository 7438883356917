export const masterString = (num, words) => {
    num = num % 100;
    if (num > 19) {
        num = num % 10;
    }
    switch (num) {
        case 1: {
            return(words[0]);
        }
        case 2: case 3: case 4: {
            return(words[1]);
        }
        default: {
            return(words[2]);
        }
    }
}


export const in_array = (needle, haystack) => {
    for(const i in haystack) {
        if(haystack[i] === needle) return true;
    }
    return false;
}